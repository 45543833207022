import {Alert, Button, Header, PropertyFilter, Table, TableProps} from '@amzn/awsui-components-react';
import React, {useEffect, useState} from 'react';
import {PropertyFilterProperty, useCollection} from '@amzn/awsui-collection-hooks';
import {PraxisAPI} from 'src/api/PraxisAPI';
import {TableEmptyState} from 'src/components/table/TableEmptyState';
import {TableNoMatchState} from 'src/components/table/TableNoMatchState';

const FILTERING_PROPERTIES: ReadonlyArray<PropertyFilterProperty> = [
    {
        propertyLabel: 'Event ID',
        key: 'eventId',
        groupValuesLabel: 'Event ID values',
        operators: ['>', '>=', '<', '<=', '=']
    },
    {
        propertyLabel: 'Name',
        key: 'name',
        groupValuesLabel: 'Name values',
        operators: [':', '!:', '=', '!=']
    },
    {
        propertyLabel: 'Blocker',
        key: 'blocker',
        groupValuesLabel: 'Blocker values',
        operators: [':', '!:', '=', '!=']
    },
    {
        propertyLabel: 'Scope',
        key: 'scope',
        groupValuesLabel: 'Scope values',
        operators: [':', '!:', '=', '!=']
    },
    {
        propertyLabel: 'Status',
        key: 'status',
        groupValuesLabel: 'Status values',
        operators: [':', '!:', '=', '!=']
    },
    {
        propertyLabel: 'Size',
        key: 'size',
        groupValuesLabel: 'Size values',
        operators: ['>', '>=', '<', '<=', '=']
    },
    {
        propertyLabel: 'Notes',
        key: 'notes',
        groupValuesLabel: 'Notes values',
        operators: [':', '!:', '=', '!=']
    },
    {
        propertyLabel: 'Created On',
        key: 'createdOn',
        groupValuesLabel: 'Created At values',
        operators: [':', '!:', '>', '>=', '<', '<=', '=']
    },
    {
        propertyLabel: 'Created By',
        key: 'createdBy',
        groupValuesLabel: 'Created By values',
        operators: [':', '!:', '=', '!=']
    },
    {
        propertyLabel: 'Expires On',
        key: 'expiresOn',
        groupValuesLabel: 'Expires At values',
        operators: [':', '!:', '>', '>=', '<', '<=', '=']
    },
];

const columnDefinitions: readonly TableProps.ColumnDefinition<any>[] = [
    {
        id: 'eventId',
        header: 'Event ID',
        cell: e => e.eventId,
        sortingField: 'eventId',
        width: 130,

    },
    {
        id: 'name',
        header: 'Name',
        cell: e => e.name,
        sortingField: 'name',
        width: 200,
    },
    {
        id: 'blocker',
        header: 'Blocker',
        cell: e => e.blocker,
        sortingField: 'blocker',
        width: 170,
    },
    {
        id: 'scope',
        header: 'Scope',
        cell: e => e.scope,
        sortingField: 'scope',
        width: 150,
    },
    {
        id: 'status',
        header: 'Status',
        cell: e => e.status,
        sortingField: 'status',
        width: 120,
    },
    {
        id: 'size',
        header: 'Size',
        cell: e => e.size,
        sortingField: 'size',
        width: 100,
    },
    {
        id: 'notes',
        header: 'Notes',
        cell: e => e.notes,
        sortingField: 'notes',
    },
    {
        id: 'createdBy',
        header: 'Created By',
        cell: e => e.createdBy,
        sortingField: 'createdBy',
        width: 120,
    },
    {
        id: 'createdOn',
        header: 'Created On',
        cell: e => e.createdOn,
        sortingField: 'createdOn',
        width: 150,
    },
    {
        id: 'expiresOn',
        header: 'Expires On',
        cell: e => e.expiresOn,
        sortingField: 'expiresOn',
        width: 150,
    },
];

export interface ListStatusUpdatesViewProps {
    userName: string;
}

export function ListStatusUpdatesView(props: ListStatusUpdatesViewProps) {

    const queryString = window.location.search.length > 0 ?
        window.location.search.substring(0, window.location.search.length - 1) : '';
    const urlParams = new URLSearchParams(queryString);

    const [statusUpdates, updateStatusUpdates] = useState([]);
    const [
        selectedItems,
        updatedSelectedItems
    ] = React.useState([]);
    const [downloadButtonDisabled, updateDownloadButtonDisabled] = useState(true);
    const [isTableLoading, updateIsTableLoading] = useState(false);
    const [errorMessage, updateErrorMessage] = useState('');
    //For more info about collectionHooks https://polaris.a2z.com/get_started/dev_guides/collection_hooks/
    const {items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps} = useCollection(
        statusUpdates,
        {
            propertyFiltering: {
                filteringProperties: FILTERING_PROPERTIES,
                empty: (<TableEmptyState resourceName='Status Update'/>),
                noMatch: (
                    <TableNoMatchState
                        onClearFilter={() => {
                            actions.setPropertyFiltering({tokens: [], operation: 'and'});
                        }}
                    />
                ),
                defaultQuery: urlParams.get('eventId') ? {
                    tokens: [{
                        value: urlParams.get('eventId')!,
                        propertyKey: 'eventId',
                        operator: '=',
                    }],
                    operation: 'and',
                } : (props.userName ? {
                    tokens: [{
                        value: props.userName.substring(props.userName.lastIndexOf('_') + 1),
                        propertyKey: 'createdBy',
                        operator: '=',
                    }],
                    operation: 'and',
                } : {
                    tokens: [{
                        value: '',
                        propertyKey: 'createdBy',
                        operator: '!=',
                    }],
                    operation: 'and',
                }),
            },
            sorting: {
                defaultState: {
                    isDescending: true,
                    sortingColumn: {
                        sortingField: 'number',
                    }
                }
            },
            selection: {}
        }
    );

    function convertDateToString(date: Date): string {
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-\
${date.getDate().toString().padStart(2, '0')}`;
    }

    useEffect(() => {
        updateIsTableLoading(true);
        PraxisAPI.getAllStatusUpdates('CA')
            .then(response => {
                if (response.statusUpdateEvents && response.statusUpdateEvents.length > 0) {
                    let statusUpdatedEvents = response.statusUpdateEvents.map((event: any) => {
                        return {
                            ...event,
                            eventId: event.cc.concat('-', event.number),
                            createdOn: convertDateToString(new Date(event.createdAt * 1000)),
                            expiresOn: event.expiresAt == undefined ? '' : convertDateToString(new Date(event.expiresAt * 1000)),
                        }
                    })
                    updateStatusUpdates(statusUpdatedEvents);
                }
                updateIsTableLoading(false);

            }).catch(e => {
            updateIsTableLoading(false);
            console.log(e);
            if (e.response != null && e.response.data) {
                updateErrorMessage(`An error occurred while getting all Status Updates: ${e.response.data.errorMessage}, refresh the page to retry.`);
            } else {
                updateErrorMessage('An error occurred while getting all Status Updates, refresh the page to retry.');
            }
        });
    }, []);

    useEffect(() => {
        updateDownloadButtonDisabled(!selectedItems || selectedItems.length == 0);
    }, [selectedItems]);

    async function downloadDetails() {
        updateDownloadButtonDisabled(true);
        PraxisAPI.getStatusUpdateDetailsUrl(selectedItems[0]["cc"], selectedItems[0]["number"])
            .then(response => {
                window.open(response.downloadUrl);
            })
            .catch(e => {
                if (e.response != null && e.response.data) {
                    updateErrorMessage(`An error occurred while getting download URL of eventId: ${selectedItems[0]["eventId"]} with message: ${e.response.data.errorMessage}`);
                } else {
                    updateErrorMessage(`An error occurred while getting download URL of eventId: ${selectedItems[0]["eventId"]}`);
                }
            })
            .finally(()=>  updateDownloadButtonDisabled(false));
    }

    // For more information about polaris Table https://polaris.a2z.com/components/awsui-table/?example=common-table&tabId=playground
    return (
        <div>
            {
                errorMessage && <Alert
                    type='error'
                    dismissible={true}
                    onDismiss={()=> updateErrorMessage('')}
                >
                    {errorMessage}
                </Alert>
            }
            <Table
                {...collectionProps}
                items={items}
                columnDefinitions={columnDefinitions}
                visibleColumns={[
                    'eventId',
                    'name',
                    'blocker',
                    'scope',
                    'status',
                    'size',
                    'notes',
                    'createdBy',
                    'createdOn',
                    'expiresOn',
                ]}
                selectedItems={selectedItems}
                onSelectionChange={({detail}) => {
                    updatedSelectedItems(detail.selectedItems);
                }
                }
                selectionType='single'
                stickyHeader={true}
                resizableColumns={true}
                wrapLines={true}
                loading={isTableLoading}
                loadingText='Loading status updates'
                header={
                    <Header variant='h1'
                            actions={<Button
                                id='download'
                                variant='primary'
                                disabled={downloadButtonDisabled}
                                onClick={downloadDetails}
                            >
                                Download Details Of Selected Item
                            </Button>}
                    >
                        CA Status Updates Summary
                    </Header>
                }
                filter={
                    <PropertyFilter
                        i18nStrings={{
                            filteringAriaLabel: 'your choice',
                            dismissAriaLabel: 'Dismiss',
                            filteringPlaceholder: 'Search',
                            groupValuesText: 'Values',
                            groupPropertiesText: 'Properties',
                            operatorsText: 'Operators',
                            operationAndText: 'and',
                            operationOrText: 'or',
                            operatorLessText: 'Less than',
                            operatorLessOrEqualText: 'Less than or equal',
                            operatorGreaterText: 'Greater than',
                            operatorGreaterOrEqualText:
                                'Greater than or equal',
                            operatorContainsText: 'Contains',
                            operatorDoesNotContainText: 'Does not contain',
                            operatorEqualsText: 'Equals',
                            operatorDoesNotEqualText: 'Does not equal',
                            editTokenHeader: 'Edit filter',
                            propertyText: 'Property',
                            operatorText: 'Operator',
                            valueText: 'Value',
                            cancelActionText: 'Cancel',
                            applyActionText: 'Apply',
                            allPropertiesLabel: 'All properties',
                            tokenLimitShowMore: 'Show more',
                            tokenLimitShowFewer: 'Show fewer',
                            clearFiltersText: 'Clear filters',
                            removeTokenButtonAriaLabel: () => 'Remove token',
                            enteredTextLabel: text => `Use: '${text}'`
                        }}
                        {...propertyFilterProps}
                    />
                }
            />
        </div>
    );
}

export default ListStatusUpdatesView;