import {Alert, Spinner} from '@amzn/awsui-components-react';
import QuickSightDashboard from 'src/components/QuickSightDashboard';
import React, {useEffect, useState} from 'react';
import {PraxisAPI} from 'src/api/PraxisAPI';
import {CognitoUser} from 'amazon-cognito-identity-js';

interface QuickSightDashboardViewProps {
    user: CognitoUser | any
    country: string
    authError: null | string
}

export function QuickSightDashboardView (props: QuickSightDashboardViewProps) {

    const [dashboardUrl, updateDashboardUrl] = useState('');
    const [errorMessage, updateErrorMessage] = useState('');

    useEffect(() => {
        if (props.authError) {
            updateErrorMessage(props.authError);
        }
    }, [props.authError]);

    useEffect(() => {
        if (props.user) {
            updateDashboardUrl('');
            PraxisAPI.getDashboardUrl(`${props.country}_ASIN_PARITY`)
                .then(response => {
                    updateDashboardUrl(response.url);
                })
                .catch(e => {
                    if (e.response != null) {
                        if (e.response.status == 403) {
                            updateErrorMessage(`Not authorized to view dashboard ${props.country}_ASIN_PARITY.`);
                        } else if (e.response.data) {
                            updateErrorMessage(`An error occurred while rendering this page: ${e.response.data.errorMessage}`);
                        } else {
                            updateErrorMessage('An error occurred while rendering this page');
                        }
                    } else {
                        updateErrorMessage('An error occurred while rendering this page');
                    }
                });
        }
    }, [props.user, props.country]);

    return <div>
        {
            errorMessage && <Alert
                type='error'
            >
                {errorMessage}
            </Alert>
        }
        {(!dashboardUrl && !errorMessage) &&
        <div className='spinner-center'><Spinner size='large'/><span>Loading...</span></div>}
        {dashboardUrl && <QuickSightDashboard url={dashboardUrl}/>}
    </div>
}
export default QuickSightDashboardView;