import {
    Alert,
    Button,
    Checkbox,
    Container,
    Form,
    FormField,
    Header,
    Input,
    RadioGroup,
    SpaceBetween,
    Textarea
} from '@amzn/awsui-components-react';
import React, {useEffect, useRef} from 'react';
import {PraxisAPI} from 'src/api/PraxisAPI';
import {Notifications} from 'src/components/Notifications';

export function CreateStatusUpdateView() {

    enum Scope {
        VendorCode = 'VendorCode',
        ASIN = 'ASIN',
        CompanyCode = 'CompanyCode',
        CompanyCodeGL = 'CompanyCodeGL',
        ASINPair = 'ASINPair',
    }

    enum Blocker {
        VendorRestrictions = 'VendorRestrictions',
        ASINBlocklist = 'ASINBlocklist',
        HighRisk = 'HighRisk',
        EquivalentSelection = 'EquivalentSelection',
    }

    enum Status {
        Paused = 'Paused',
        InProgress = 'In Progress',
        Equivalent = 'Equivalent',
        NonEquivalent = 'Non-equivalent',
    }
    enum ExpiryPolicy {
        ThreeMonths = 'THREE_MONTHS',
        SixMonths = 'SIX_MONTHS',
        OneYear = 'ONE_YEAR'
    }

    const VALID_COMPANY_CODE = new RegExp('^C/.+$');
    const VALID_VENDOR_CODE = new RegExp('^[a-zA-Z0-9]{2,5}$');
    const VALID_ASIN = new RegExp('^[a-zA-Z0-9]{10}$');
    const VALID_ASIN_PAIR = new RegExp('^[a-zA-Z0-9]{10},[a-zA-Z0-9]{10}$');
    const REPEATED_ASIN_PAIR = new RegExp('^(.{10}),\\1$');

    const scopesByBlocker = new Map ([
        [Blocker.VendorRestrictions,  [Scope.VendorCode]],
        [Blocker.ASINBlocklist,  [Scope.ASIN]],
        [Blocker.HighRisk,  [Scope.CompanyCode, Scope.CompanyCodeGL]],
        [Blocker.EquivalentSelection, [Scope.ASINPair]],
    ]);
    const statusesByBlocker = new Map ([
        [Blocker.VendorRestrictions,  [Status.Paused, Status.InProgress]],
        [Blocker.ASINBlocklist,  [Status.Paused, Status.InProgress]],
        [Blocker.HighRisk,  [Status.Paused, Status.InProgress]],
        [Blocker.EquivalentSelection, [Status.Equivalent, Status.NonEquivalent]],
    ]);
    const itemsLabelByScope = new Map([
        [Scope.VendorCode, 'Vendor Codes'],
        [Scope.ASIN, 'ASINs'],
        [Scope.CompanyCode, 'Company Codes'],
        [Scope.CompanyCodeGL, 'Company Codes'],
        [Scope.ASINPair, 'ASIN Pairs'],
    ]);

    const relatedItemsPlaceholderByScope = new Map([
        [Scope.VendorCode, 'HEWG9\nLEFY9'],
        [Scope.ASIN, 'B085D4MFS8\nB00X7R1FZ2'],
        [Scope.CompanyCode, 'C/IROC1\nC/KIMYB'],
        [Scope.CompanyCodeGL, 'C/IROC1\nC/KIMYB'],
        [Scope.ASINPair, 'B08HP4K7KC,B08P646ZP9\nB08J6FVJMX,B08J98JL4W'],
    ]);

    const queryString = window.location.search.length > 0 ?
        window.location.search.substring(0, window.location.search.length - 1) : '';
    const urlParams = new URLSearchParams(queryString);
    
    const [name, updateName] = React.useState('');
    const [blocker, updateBlocker] = React.useState(urlParams.get('blocker') ? urlParams.get('blocker')! as Blocker: Blocker.VendorRestrictions);
    const [scope, updateScope] = React.useState(urlParams.get('scope') ? urlParams.get('scope')! as Scope : Scope.VendorCode);
    const [status, updateStatus] = React.useState(Status.Paused);
    const [items, updateItems] = React.useState(urlParams.get('items') ? urlParams.get('items')! : '');
    const [notes, updateNotes] = React.useState('');
    const [expiryPolicy, updateExpiryPolicy] = React.useState(ExpiryPolicy.ThreeMonths);
    const [showGLs, updateShowGLs] = React.useState(false);
    const [showExpiryDates, updateShowExpiryDates] = React.useState(true);
    const [productFamily, updateProductFamily] = React.useState('CE');
    const [PFToGLMap, updatePFToGLMap] = React.useState(new Map([
        ['CE', [
            {value: 23, description: 'gl_electronics', checked: false},
            {value: 107, description: 'gl_wireless', checked: false},
            {value: 147, description: 'gl_pc', checked: false},
            {value: 229, description: 'gl_office_product', checked: false},
            {value: 267, description: 'gl_musical_instruments', checked: false},
            {value: 421, description: 'gl_camera', checked: false},
            {value: 422, description: 'gl_mobile_electronics', checked: false},
            {value: 504, description: 'gl_home_entertainment', checked: false}]],
        ['Consumables', [
            {value: 75, description: 'gl_baby_product', checked: false},
            {value: 121, description: 'gl_drugstore', checked: false},
            {value: 194, description: 'gl_beauty', checked: false},
            {value: 325, description: 'gl_grocery', checked: false},
            {value: 364, description: 'gl_personal_care_appliances', checked: false},
            {value: 510, description: 'gl_luxury_beauty', checked: false}]],
        ['HRV', [
            {value: 21, description: 'gl_toy', checked: false},
            {value: 60, description: 'gl_home_improvement', checked: false},
            {value: 200, description: 'gl_sports', checked: false},
            {value: 263, description: 'gl_automotive', checked: false},
            {value: 265, description: 'gl_major_appliances', checked: false},
            {value: 293, description: 'gl_tires', checked: false},
            {value: 328, description: 'gl_biss', checked: false},
            {value: 468, description: 'gl_outdoors', checked: false},
            {value: 469, description: 'gl_tools', checked: false}]],
        ['Lifestyles', [
            {value: 79, description: 'gl_kitchen', checked: false},
            {value: 86, description: 'gl_lawn_and_garden', checked: false},
            {value: 196, description: 'gl_furniture', checked: false},
            {value: 199, description: 'gl_pet_products', checked: false},
            {value: 201, description: 'gl_home', checked: false}]],
        ['Media', [
            {value: 14, description: 'gl_book', checked: false},
            {value: 15, description: 'gl_music', checked: false},
            {value: 63, description: 'gl_video_games', checked: false},
            {value: 65, description: 'gl_software', checked: false},
            {value: 74, description: 'gl_dvd', checked: false}]],
        ['Softlines', [
            {value: 193, description: 'gl_apparel', checked: false},
            {value: 197, description: 'gl_jewelry', checked: false},
            {value: 198, description: 'gl_luggage', checked: false},
            {value: 241, description: 'gl_watch', checked: false},
            {value: 309, description: 'gl_shoes', checked: false},
            {value: 641, description: 'gl_softlines_private_label', checked: false}]]
    ]));
    const [notifications, updateNotifications] = React.useState(new Array());

    const notificationsRef = useRef<HTMLDivElement>(null);

    const updateGLWhenProductFamilyChanged = (index: number,
                                              checked: boolean,
                                              productFamily: string) => {
        let newArr = [...PFToGLMap.get(productFamily)!];
        newArr[index].checked = checked;
        updatePFToGLMap(prev => new Map(PFToGLMap).set(productFamily, newArr));
    }

    useEffect(() => {
            if (blocker == Blocker.VendorRestrictions) {
                updateScope(Scope.VendorCode);
                updateStatus(Status.Paused);
                updateShowExpiryDates(true);
            } else if (blocker == Blocker.ASINBlocklist) {
                updateScope(Scope.ASIN);
                updateStatus(Status.Paused);
                updateShowExpiryDates(true);
            } else if (blocker == Blocker.EquivalentSelection) {
                updateScope(Scope.ASINPair);
                updateStatus(Status.Equivalent);
                updateShowExpiryDates(false);
            } else {
                updateScope(Scope.CompanyCode);
                updateStatus(Status.Paused);
                updateShowExpiryDates(true);
            }
        },
        [blocker]
    );

    useEffect(() => updateShowGLs(scope == Scope.CompanyCodeGL),
        [scope]
    );

    const [nameError, updateNameError] = React.useState('');
    useEffect(() => {
            if (!name || name.length == 0) {
                updateNameError('Name must be non-empty.');
            }
            else {
                updateNameError('')
            }
        },
        [name]);

    const [notesError, updateNotesError] = React.useState('');
    useEffect(() => {
            if (!notes || notes.length == 0) {
                updateNotesError('Please describe the business justification for this action. Add links if possible.');
            }
            else {
                updateNotesError('')
            }
        },
        [notes]);

    const [itemsError, updateItemsError] = React.useState('');
    useEffect(() => {
            if (scope == Scope.ASINPair && (!items || items.length == 0)) {
                updateItemsError(itemsLabelByScope.get(scope) + ' must be non-empty and in the form [US ASIN,CA ASIN], with one pair per line.');
            } else if (!items || items.length == 0) {
                updateItemsError(itemsLabelByScope.get(scope) + ' must be non-empty.');
            } else if ([Scope.CompanyCode, Scope.CompanyCodeGL].includes(scope)
                && items.split(/\n/)
                    .filter(line =>
                        !VALID_COMPANY_CODE.test(line)
                    ).length > 0) {
                updateItemsError(itemsLabelByScope.get(scope) + ' must start with C/.');
            }
            else if (scope == Scope.VendorCode
                && items.split(/\n/)
                    .filter(line =>
                        !VALID_VENDOR_CODE.test(line)
                    ).length > 0) {
                updateItemsError(itemsLabelByScope.get(scope) + ' must be alphanumeric and have a length between 2 and 5.');
            }
            else if (scope == Scope.ASIN
                && items.split(/\n/)
                    .filter(line =>
                        !VALID_ASIN.test(line)
                    ).length > 0) {
                updateItemsError(itemsLabelByScope.get(scope) + ' must be alphanumeric and have a length of 10.');
            }
            else if (scope == Scope.ASINPair
                && items.split(/\n/)
                    .filter(line =>
                        !VALID_ASIN_PAIR.test(line)
                    ).length > 0) {
                updateItemsError(itemsLabelByScope.get(scope) + ' must be alphanumeric, have a length of 10, and be separated by a comma.');
            }
            else if (scope == Scope.ASINPair
                    && items.split(/\n/)
                        .filter(line =>
                            REPEATED_ASIN_PAIR.test(line)
                        ).length > 0) {
                    updateItemsError(itemsLabelByScope.get(scope) + ' must contain two distinct ASINs.');
            }
            else {
                updateItemsError('')
            }
        },
        [items, scope]);

    const [gLsError, updateGLsError] = React.useState('');
    useEffect(() => {
        if(showGLs && PFToGLMap.get(productFamily)!
            .filter(glOption => glOption.checked)
            .map(glOption => glOption.value).length < 1) {
            updateGLsError('Please select at least one GL.');
        }
        else {
            updateGLsError('');
        }
    },
        [showGLs, PFToGLMap]);

    const [createButtonDisabled, updateCreateButtonDisabled] = React.useState(true);
    useEffect(() => {
            updateCreateButtonDisabled((nameError || notesError || itemsError || gLsError) != '');
        },
        [nameError, notesError, itemsError, gLsError]);

    /**
     * This function tries to create a StatusUpdate.
     */
    async function createStatusUpdate() {
        updateCreateButtonDisabled(true);
        const body = {
            cc: 'CA',
            name: name,
            blocker: blocker,
            scope: scope,
            status: status,
            items: items,
            notes: notes,
            expiryPolicy: showExpiryDates ? expiryPolicy : null,
            gls: showGLs ? PFToGLMap.get(productFamily)!
                .filter(glOption => glOption.checked)
                .map(glOption => glOption.value) : []

        };
        PraxisAPI.createStatusUpdate(body)
            .then(response => {
                let newArray = [...notifications];
                const message = `Successfully created Status Update with id: ${response.cc}-${response.number}`;
                newArray.push({
                    type: 'success',
                    content: message,
                    action: <Button
                        variant='normal'
                        href={`/?eventId=${response.cc}-${response.number}/#/ca/status_updates/list`}
                        target='_blank'
                    >
                        View Status Update
                    </Button>,
                    dismissible: true,
                    onDismiss: () => {
                        let newArray = [...notifications];
                        newArray.filter(value => value.content != message)
                        updateNotifications(newArray);
                    }
                });
                updateNotifications(newArray);
                if(notificationsRef.current) {
                    notificationsRef.current.scrollIntoView();
                }
                updateCreateButtonDisabled(false);
            })
            .catch(e => {
                let newArray = [...notifications];
                let message: string;
                if(e.response && e.response.data) {
                    message = `An error occurred while creating Status Update: ${e.response.data.errorMessage}`;
                }
                else {
                    message = 'An error occurred while creating Status Update';
                }
                newArray.push({
                    type: 'error',
                    content: message,
                    dismissible: true,
                    onDismiss: () => {
                        let newArray = [...notifications];
                        newArray.filter(value => value.content != message)
                        updateNotifications(newArray);
                    }
                });
                updateNotifications(newArray);
                if(notificationsRef.current) {
                    notificationsRef.current.scrollIntoView();
                }
                updateCreateButtonDisabled(false);
            });
    }

    return (
        <Form>

            <Container
                header={
                    <Header
                        variant='h1'
                        description='Please fill in the details and click Create Status Update'
                    >
                        Create Status Update for CA
                    </Header>
                }
            >

                <SpaceBetween direction='vertical' size='l'>
                    <div ref={notificationsRef}>
                        <Notifications items={notifications}/>
                    </div>
                    <FormField label='Name'
                               className='name'
                               description='The name of this status update.'>
                        {nameError && <Alert
                            type='info'
                        >
                            {nameError}
                        </Alert>}
                        <Input className='nameInput' value={name} onChange={({detail}) => updateName(detail.value)}/>
                    </FormField>
                    <FormField label='Blocker Type'>
                        <RadioGroup
                            className='blocker'
                            onChange={({detail}) => updateBlocker(detail.value as Blocker)}
                            value={blocker}
                            items={[
                                {
                                    value: Blocker.VendorRestrictions,
                                    label: 'Vendor Restrictions'
                                },
                                {
                                    value: Blocker.ASINBlocklist,
                                    label: 'ASIN Blocklist',
                                },
                                {
                                    value: Blocker.HighRisk,
                                    label: 'High Risk',
                                },
                                {
                                    value: Blocker.EquivalentSelection,
                                    label: 'Equivalent Selection',
                                }
                            ]}
                        />
                    </FormField>
                    <FormField label='Scope'>
                        <RadioGroup
                            className='scope'
                            onChange={({detail}) => updateScope(detail.value as Scope)}
                            value={scope}
                            items={[
                                {
                                    value: Scope.CompanyCode,
                                    label: 'Company Code',
                                    disabled: !scopesByBlocker.get(blocker)!.some(scope => scope == Scope.CompanyCode),
                                },
                                {
                                    value: Scope.CompanyCodeGL,
                                    label: 'Company Code + GL',
                                    disabled: !scopesByBlocker.get(blocker)!.some(scope => scope == Scope.CompanyCodeGL),
                                },
                                {
                                    value: Scope.ASIN,
                                    label: 'ASIN',
                                    disabled: !scopesByBlocker.get(blocker)!.some(scope => scope == Scope.ASIN),
                                },
                                {
                                    value: Scope.VendorCode,
                                    label: 'Vendor Code',
                                    disabled: !scopesByBlocker.get(blocker)!.some(scope => scope == Scope.VendorCode),
                                },
                                {
                                    value: Scope.ASINPair,
                                    label: 'ASIN Pair',
                                    disabled: !scopesByBlocker.get(blocker)!.some(scope => scope == Scope.ASINPair),
                                }
                            ]}
                        />
                    </FormField>
                    {showGLs && <FormField label='Product Family'>
                        <RadioGroup
                            className='product-family'
                            onChange={({detail}) => updateProductFamily(detail.value)}
                            value={productFamily}
                            items={[
                                {
                                    value: 'CE',
                                    label: 'CE',
                                },
                                {
                                    value: 'Consumables',
                                    label: 'Consumables',
                                },
                                {
                                    value: 'HRV',
                                    label: 'HRV',
                                },
                                {
                                    value: 'Lifestyles',
                                    label: 'Lifestyles',
                                },
                                {
                                    value: 'Media',
                                    label: 'Media',
                                },
                                {
                                    value: 'Softlines',
                                    label: 'Softlines',
                                }
                            ]}
                        />
                    </FormField>}
                    {showGLs && <FormField label='GLs'>
                        {gLsError && <Alert
                            type='info'
                        >
                            {gLsError}
                        </Alert>}
                        {
                            PFToGLMap.get(productFamily)!.map((value, index) => {
                                return <Checkbox className={`checkbox-${index.toString()}`}
                                                checked={value.checked}
                                                 key={index}
                                                 onChange={({detail}) => updateGLWhenProductFamilyChanged(index, detail.checked, productFamily)
                                                 }
                                >
                                    {value.description + ' ' + value.value}
                                </Checkbox>
                            })
                        }
                    </FormField>}
                    <FormField label='Set Status To'>
                        <RadioGroup
                            className='status'
                            onChange={({detail}) => updateStatus(detail.value as Status)}
                            value={status}
                            items={[
                                {
                                    value: Status.Paused,
                                    label: Status.Paused,
                                    disabled: !statusesByBlocker.get(blocker)!.some(status => status == Status.Paused),
                                },
                                {
                                    value: Status.InProgress,
                                    label: Status.InProgress,
                                    disabled: !statusesByBlocker.get(blocker)!.some(status => status == Status.InProgress),
                                },
                                {
                                    value: Status.Equivalent,
                                    label: Status.Equivalent,
                                    disabled: !statusesByBlocker.get(blocker)!.some(status => status == Status.Equivalent),
                                },
                                {
                                    value: Status.NonEquivalent,
                                    label: Status.NonEquivalent,
                                    disabled: !statusesByBlocker.get(blocker)!.some(status => status == Status.NonEquivalent),
                                }
                            ]}
                        />
                    </FormField>
                    <FormField label={itemsLabelByScope.get(scope)}>
                        {itemsError && <Alert
                            className='itemsAlert'
                            type='info'
                        >
                            {itemsError}
                        </Alert>}
                        <Textarea
                            className='items'
                            onChange={({detail}) => updateItems(detail.value)}
                            onKeyUp={() => {
                                if (scope == Scope.ASINPair) {
                                    updateItems(items.split(/\n/)
                                        .map(line => line.replace('\t', ',').replace(' ', ',')).join('\n'));
                                }
                            }}
                            onBlur={()=> {
                                if(scope == Scope.ASIN) {
                                    updateItems(items.split(/\n/)
                                        .filter(line => line.trim() !== '')
                                        .map(line => line.padStart(10, '0')).join('\n'));
                                } else if (scope == Scope.ASINPair) {
                                    updateItems(items.split(/\n/)
                                        .filter(line => line.trim() !== ''  && line.trim() !== ',')
                                        .map(line => line.split(',')
                                            .map(asin => asin.padStart(10, '0')).join(',')).join('\n'));
                                }
                            }}
                            value={items}
                            placeholder={relatedItemsPlaceholderByScope.get(scope)}
                            rows={5}
                        />
                    </FormField>
                    <FormField label='Notes'>
                        {notesError && <Alert
                            type='info'
                        >
                            {notesError}
                        </Alert>}
                        <Textarea
                            className='notes'
                            onChange={({detail}) => updateNotes(detail.value)}
                            value={notes}
                            placeholder='This is because ... . See https://sim.amazon.com/EXAMPLE-123'
                        />
                    </FormField>
                    {showExpiryDates && <FormField label='Update Status for'>
                        <RadioGroup
                            className='period'
                            onChange={({detail}) => updateExpiryPolicy(detail.value as ExpiryPolicy)}
                            value={expiryPolicy}
                            items={[
                                {
                                    value: ExpiryPolicy.ThreeMonths,
                                    label: '3 Months',
                                },
                                {
                                    value: ExpiryPolicy.SixMonths,
                                    label: '6 Months',
                                },
                                {
                                    value: ExpiryPolicy.OneYear,
                                    label: '1 Year',
                                }
                            ]}
                        />
                    </FormField>}
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button
                            className='create-status-update'
                            variant='primary'
                            disabled={createButtonDisabled}
                            onClick={createStatusUpdate}>Create Status Update</Button>
                    </SpaceBetween>
                </SpaceBetween>

            </Container>
        </Form>
    );
}
export default CreateStatusUpdateView;
