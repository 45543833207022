import {Box, Button, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';



export function TableNoMatchState(props: any) {
    return (
        <Box margin={{vertical: 'xs'}} textAlign='center' color='inherit'>
            <SpaceBetween size='xxs'>
                <div>
                    <b>No matches</b>
                    <Box variant='p' color='inherit'>
                        We can't find a match.
                    </Box>
                </div>
                <Button onClick={props.onClearFilter}>Clear filter</Button>
            </SpaceBetween>
        </Box>
    );
}