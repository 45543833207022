import {API, Auth} from "aws-amplify";

/**
 * This function make a HTTP GET call to target.
 * For more info about amplify API
 * https://docs.amplify.aws/lib/restapi/fetch/q/platform/js
 * @param apiName
 * @param path
 */
export async function get(apiName: string, path: string) {
    return API
        .get(apiName, path, {
            headers: {
                'Authorization': (await Auth.currentSession()).getIdToken().getJwtToken(),
                'Content-type': 'application/json'
            },
            response: false,
        });
}

/**
 * This function make a http POST call to target.
 * For more info about amplify API
 * https://docs.amplify.aws/lib/restapi/update/q/platform/js
 * @param apiName
 * @param path
 */
export async function post(apiName: string, path: string, body: any) {
    return API
        .post(apiName, path, {
            headers: {
                'Authorization': (await Auth.currentSession()).getIdToken().getJwtToken(),
                'Content-type': 'application/json'
            },
            response: false,
            body: body
        });
}