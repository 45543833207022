import {Box, SpaceBetween} from '@amzn/awsui-components-react';
import React from 'react';
interface TableEmptyStateProps {
    resourceName: string;
}

export function TableEmptyState (props: TableEmptyStateProps) {
    return (<Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
            <SpaceBetween size='xxs'>
                <div>
                    <b>No {props.resourceName.toLowerCase()}s</b>
                    <Box variant='p' color='inherit'>
                        No {props.resourceName.toLowerCase()}s associated with this resource.
                    </Box>
                </div>
            </SpaceBetween>
        </Box>
    );
}

