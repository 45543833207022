import {useEffect} from 'react';
import React from 'react';

const QuickSightEmbedding = require('amazon-quicksight-embedding-sdk');

export function QuickSightDashboard(props: Record<string, string>) {

    useEffect(() => {
        if (document.getElementById('dashboardContainer')) {
            document.getElementById('dashboardContainer')!.innerHTML = "";
        }
        //For more information about quicksight embedding config https://www.npmjs.com/package/amazon-quicksight-embedding-sdk
        const options = {
            url: props.url,
            container: document.getElementById('dashboardContainer'),
            scrolling: 'yes',
            height: 'AutoFit',
            width: '100%',
            footerPaddingEnabled: true
        };
        QuickSightEmbedding.embedDashboard(options);
    }, [props.url]);

    return (
        <div id='dashboardContainer'></div>
    );
}

export default QuickSightDashboard;