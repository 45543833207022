import React, {useEffect, useState} from 'react';
import SideNavigation, {SideNavigationProps} from '@amzn/awsui-components-react/polaris/side-navigation';

const CROP_URL = 'https://crop-status-na-prod.amazon.com';
const TICKET_URL = 'https://t.corp.amazon.com/create/templates/e7d1bb76-760b-48d0-8505-ede73ce0beb4';
const USER_GUIDE_URL_CA = 'https://w.amazon.com/bin/view/NAFN/Enrollment_Maximization_System/User_Guide';
const USER_GUIDE_URL_MX = 'https://w.amazon.com/bin/view/NAFN/Enrollment_Maximization_System/User_Guide_(MX)';
interface SideNavigationBarProps {
    history: any;
    initialActiveHref: string;
    showCanadaLinks: boolean;
    showMexicoLinks: boolean;
}

export function SideNavigationBar(props: SideNavigationBarProps) {
    const [activeHref, setActiveHref] = useState(props.initialActiveHref);

    useEffect(() => {
        setActiveHref(props.initialActiveHref);
    }, [props.initialActiveHref]);

    const internalLinksCA: ReadonlyArray<any> = [
        {type: 'link', text: 'Parity Dashboard', href: '/ca/parity_dashboard'},
        {type: 'link', text: "Create Status Update", href: '/ca/status_updates/create'},
        {type: 'link', text: "List Status Updates", href: '/ca/status_updates/list'},
        {type: 'link', text: "User Guide", href: USER_GUIDE_URL_CA, external: true},
    ];

    const caSection: SideNavigationProps.Item = {
        type: 'section',
        text: 'Canada',
        items: internalLinksCA,
        defaultExpanded: true
    };

    const internalLinksMX: ReadonlyArray<any> = [
        {type: 'link', text: 'Parity Dashboard', href: '/mx/parity_dashboard'},
        {type: 'link', text: "User Guide", href: USER_GUIDE_URL_MX, external: true},
    ];

    const mxSection: SideNavigationProps.Item = {
        type: 'section',
        text: 'Mexico',
        items: internalLinksMX,
        defaultExpanded: true
    };

    const navSections: SideNavigationProps.Item[] = [];

    if (props.showCanadaLinks) {
        navSections.push(caSection);
    }

    if (props.showMexicoLinks) {
        navSections.push(mxSection);
    }

    navSections.push(
        {type: 'divider'},
        {
            type: 'link',
            text: 'CROP',
            href: CROP_URL,
            external: true
        },
        {
            type: 'link',
            text: 'Cut A Ticket',
            href: TICKET_URL,
            external: true
        });

    return (
        <SideNavigation
            activeHref={activeHref}
            header={{href: '/', text: 'EMS'}}
            onFollow={event => {
                if (!event.detail.external) {
                    event.preventDefault();
                    setActiveHref(event.detail.href);
                    props.history.push(event.detail.href);
                }
            }}
            items={navSections}
        />
    );
}

export default SideNavigationBar;
