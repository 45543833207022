import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import katalReady from '@amzn/katal-components-ready';
import App from './components/App';
import './i18n';
import './index.scss';
import {Amplify} from "aws-amplify";
import {PRAXIS_API} from './api/PraxisAPI';
import '@amzn/awsui-global-styles/polaris.css';
import {HashRouter} from "react-router-dom";

katalReady(() => {
  // The Suspense component causes React to display the fallback KatSpinner until
  // the i18n translation strings have been loaded.
    const callbackUrl = window.location.origin;
    // For more info about authentication https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource
    // For more info about API https://docs.amplify.aws/lib/restapi/getting-started/q/platform/js#manual-setup-import-existing-rest-api
    Amplify.configure({
        Auth: {
            identityPoolId: "{{CognitoIdentityPoolId}}",
            region: "{{AwsRegion}}",
            userPoolId: "{{CognitoUserPoolId}}",
            userPoolWebClientId: "{{CognitoUserPoolClientId}}",
            mandatorySignIn: false,
            oauth: {
                domain: "{{CognitoUserPoolDomainPrefix}}.auth.{{AwsRegion}}.amazoncognito.com",
                scope: ['openid'],
                redirectSignIn: callbackUrl,
                redirectSignOut: callbackUrl,
                responseType: 'code'
            },
            cookieStorage: {
                domain: window.location.hostname,
                secure: true,
            },
        },
        API: {
            endpoints: [
                {
                    name: PRAXIS_API,
                    endpoint: "{{PraxisApiUrl}}"
                }
            ]
        }
    });
  ReactDOM.render(
      <HashRouter>
        <App/>
      </HashRouter>,
    document.getElementById('root')
  );
});
