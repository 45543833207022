import {get, post} from './apiUtils';

export const PRAXIS_API = 'PraxisApi';

export class PraxisAPIClass {
    constructor() {
    }
    public getDashboardUrl(dashboardName: string): Promise<any> {
        const path = `dashboards/${dashboardName}`;
        return get(PRAXIS_API, path);
    }

    public async createStatusUpdate(body: any): Promise<any> {
        const path = 'status-updates';
        return post(PRAXIS_API, path, body);
    }

    public getAllStatusUpdates(countryCode: string): Promise<any> {
        const path = `status-updates/${countryCode}`;
        return get(PRAXIS_API, path);
    }

    public getStatusUpdateDetailsUrl(countryCode: string, number: number) {
        const path = `status-updates/${countryCode}/${number}/details`;
        return get(PRAXIS_API, path);
    }

}

export const PraxisAPI = new PraxisAPIClass();
