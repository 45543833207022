import React, {useEffect, useState} from 'react';
import {Auth} from 'aws-amplify';
import SideNavigationBar from '../components/SideNavigationBar';
import {AppLayout} from '@amzn/awsui-components-react';
import QuickSightDashboardView from 'src/components/QuickSightDashboardView';
import {Route, Switch, useHistory} from 'react-router-dom';
import CreateStatusUpdateView from 'src/components/CreateStatusUpdateView';
import ListStatusUpdatesView from 'src/components/ListStatusUpdatesView';

export function App() {

    const [user, updateUser] = useState<null | {username: any}>(null);
    const [defaultCountry, updateDefaultCountry] = useState<string>('');
    const [role, updateRole] = useState<string>('');
    const [defaultActiveHref, updateDefaultActiveHref] = useState<string>('/');
    const [showNavigationBar, updateShowNavigationBar] = useState(true);
    const [authorizationError, updateAuthorizationError] = useState<null | string>(null);
    //for more info https://reactrouter.com/web/api/Hooks/usehistory
    let history = useHistory();
    const loc = location.hash.substr(1);
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                const userRole = user.getSignInUserSession().getIdToken().decodePayload()["custom:role"];
                if (userRole) {
                    updateRole(userRole);
                    if (userRole.includes('CA-USER')) {
                        updateDefaultCountry('CA');
                        updateDefaultActiveHref('/ca/parity_dashboard');
                    } else if (userRole.includes('MX-USER')) {
                        updateDefaultCountry('MX');
                        updateDefaultActiveHref('/mx/parity_dashboard');
                    } else {
                        updateAuthorizationError('User not authorized.');
                    }
                    updateUser(user);
                } else {
                    updateAuthorizationError('User not authorized.');
                }
            })
            .catch(() => {
                console.log('No signed in user.');
                //For more info about amplify OAuth https://docs.amplify.aws/lib/auth/social/q/platform/js
                Auth.federatedSignIn({customProvider: 'AmazonFederate'})
            });
    }, []);
    return (

            //For more info https://polaris.a2z.com/components/awsui-app-layout/
            <div className='app'>
                <AppLayout
                    navigation={<SideNavigationBar history={history}
                                                   initialActiveHref={loc == '/' ? defaultActiveHref : loc}
                                                   showCanadaLinks={role.includes('CA-USER')}
                                                   showMexicoLinks={role.includes('MX-USER')}/>}
                    navigationOpen={showNavigationBar}
                    onNavigationChange={event => updateShowNavigationBar(event.detail.open)}
                    content={
                        <Switch>
                            <Route path='/' exact render={() => <QuickSightDashboardView user={user} country={defaultCountry} authError={authorizationError}/>}/>
                            <Route path='/ca/parity_dashboard'
                                   render={() => <QuickSightDashboardView user={user} country={'CA'} authError={authorizationError}/>}/>
                            <Route path='/ca/status_updates/create' render={() => <CreateStatusUpdateView/>}/>
                            <Route path='/ca/status_updates/list' render={() => <ListStatusUpdatesView userName={user ? user.username : ''}/>}/>
                            <Route path='/mx/parity_dashboard'
                                   render={() => <QuickSightDashboardView user={user} country={'MX'} authError={authorizationError}/>}/>
                        </Switch>
                    }
                    contentType='default'
                    disableContentPaddings={true}
                    toolsHide={true}
                />
            </div>

    );
}

export default App;
